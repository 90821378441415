import React, { FC, useEffect, useState } from 'react';
import FormItem from './FormItem';
import { db } from 'utils/firebase';
import Input from 'shared/Input/Input';
import { useSelector } from 'react-redux';
import CommonLayout from './CommonLayout';
import Select from 'shared/Select/Select';
import PhoneInput from 'react-phone-input-2';
import { DecodeToken } from 'utils/JwtConfig';
import 'react-phone-input-2/lib/bootstrap.css';
import Textarea from 'shared/Textarea/Textarea';
import { selectPost } from 'redux/slice/postSlice';
import { selectUser } from 'redux/slice/userSlice';
import { selectIsPageIn } from 'redux/slice/tokenSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface PostData {
    characteristics?: boolean,
    city?: boolean,
    contact_address?: boolean,
    contact_email?: boolean,
    contact_name?: boolean,
    contact_phone?: boolean,
    country?: boolean,
    description?: boolean,
    description_short?: boolean,
    images?: boolean,
    images_max?: number,
    images_min?: number,
    name?: string,
    price?: boolean,
    title?: boolean
}

const EditPostPage: FC<{}> = () => {
    const [postData, setPostData] = useState<PostData | null>();
    const [loading, setLoading] = useState(false);
    const [portalPostID, setPortalPostID] = useState('');
    const [postForm, setPostForm] = useState({
        characteristics: '',
        city: '',
        contact_address: '',
        contact_email: '',
        contact_name: '',
        contact_phone: '',
        country: '',
        description: '',
        description_short: '',
        images: null as FileList | null,
        name: '',
        price: '',
        title: ''
    })

    const PostID = useSelector(selectPost);
    const User = useSelector(selectUser);
    const Portal = useSelector(selectIsPageIn);
    const navigate = useNavigate();

    const getConfigPost = (postType: string) => {
        let PostType = '';

        switch (postType) {
            case 'Publicacion de Vehiculo':
                PostType = 'vehicle'
                break;
            case 'Venta de Productos':
                PostType = 'sales'
                break;
            case 'Inmuebles':
                PostType = 'realestate'
                break;
            case 'Oferta Laboral':
                PostType = 'joboffer'
                break;
            case 'Aviso Legal':
                PostType = 'idpost'
                break;

            default:
                PostType = 'NoExist';
                break;
        };

        db.collection('data/post_types/types').doc(PostType).get().then((PostConfig) => {
            const TempDataPost: PostData = {
                characteristics: PostConfig.data()?.characteristics,
                city: PostConfig.data()?.city,
                contact_address: PostConfig.data()?.contact_address,
                contact_email: PostConfig.data()?.contact_email,
                contact_name: PostConfig.data()?.contact_name,
                contact_phone: PostConfig.data()?.contact_phone,
                country: PostConfig.data()?.country,
                description: PostConfig.data()?.description,
                description_short: PostConfig.data()?.description_short,
                images: PostConfig.data()?.images,
                images_max: PostConfig.data()?.images_max,
                images_min: PostConfig.data()?.images_min,
                name: PostConfig.data()?.name,
                price: PostConfig.data()?.price,
                title: PostConfig.data()?.title
            };
            setPostData(TempDataPost);
        }).catch((error) => {
            console.log("🚀 ~ file: PageAddListing1.tsx:17 ~ db.collection ~ error:", error)
        })

    };

    const getPostDataToEdit = async () => {
        const UserData = await DecodeToken(User);
        db.collection(`users/${UserData?.key}/posts`).doc(PostID).get().then((DataPost) => {
            getConfigPost(DataPost.data()?.post_type);
            setPortalPostID(DataPost.data()?.portal_post_id);
            setPostForm({
                characteristics: DataPost.data()?.characteristics !== undefined ? DataPost.data()?.characteristics : '',
                city: DataPost.data()?.city !== undefined ? DataPost.data()?.city : '',
                contact_address: DataPost.data()?.contact_address !== undefined ? DataPost.data()?.contact_address : '',
                contact_email: DataPost.data()?.contact_email !== undefined ? DataPost.data()?.contact_email : '',
                contact_name: DataPost.data()?.contact_name !== undefined ? DataPost.data()?.contact_name : '',
                contact_phone: DataPost.data()?.contact_phone !== undefined ? DataPost.data()?.contact_phone : '',
                country: DataPost.data()?.country !== undefined ? DataPost.data()?.country : '',
                description: DataPost.data()?.description !== undefined ? DataPost.data()?.description : '',
                description_short: DataPost.data()?.description_short !== undefined ? DataPost.data()?.description_short : '',
                images: null as FileList | null,
                name: DataPost.data()?.name !== undefined ? DataPost.data()?.name : '',
                price: DataPost.data()?.price !== undefined ? DataPost.data()?.price : '',
                title: DataPost.data()?.title !== undefined ? DataPost.data()?.title : ''
            })
        }).catch((error) => {
            console.log("🚀 ~ file: EditPostPage.tsx:108 ~ db.collection ~ error:", error)
        })
    };

    const getNonEmptyValues = (obj: typeof postForm) => {
        const nonEmptyValues: any = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (value !== '' && value !== null) {
                nonEmptyValues[key] = value;
            }
        });
        return nonEmptyValues;
    };

    const savePublication = async () => {
        setLoading(true);
        const Data = getNonEmptyValues(postForm);
        const DataPortal = await DecodeToken(Portal);
        const DataUser = await DecodeToken(User);

        const batch = db.batch();
        const uidPortal = DataPortal?.key;

        const portalPostsRef = db.collection(`portals/${uidPortal}/posts`);

        const PortalPostRef = portalPostsRef.doc(portalPostID);

        const portalPostData = { ...Data };

        batch.update(PortalPostRef, portalPostData);


        const userPostsRef = db.collection(`users/${DataUser?.key}/posts`);

        const newUserPostRef = userPostsRef.doc(PostID);

        const userPostData = { ...Data };

        batch.update(newUserPostRef, userPostData);

        batch.commit().then(function () {
            setLoading(false);
            toast.success('¡Publicación actualizada con éxito!', {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate(-1);
        }).catch(function (error) {
            setLoading(false);
            console.error('Error al realizar las escrituras por lote:', error);
        });
    };

    useEffect(() => {
        getPostDataToEdit();
    }, [])

    return (
        <CommonLayout
            index="01"
            nextBtnText="Guardar"
            nextFunction={savePublication}
            // disbleConditionSelect={disbleConditionSelect}
            BtnLoading={loading}
        // disableConditionsInput={!isFormValid}
        >
            <>
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-2">
                        <h2 className="text-2xl font-semibold">Datos de la Publicación</h2>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                <div className="space-y-8">
                    {/* ITEM */}
                    {postData?.title === true &&
                        <FormItem
                            label="Titulo de la publicación"
                        // desc="A catchy name usually includes: House name + Room name + Featured property + Tourist destination"
                        >
                            <Input placeholder="..." defaultValue={postForm.title} onChange={(e) => { setPostForm({ ...postForm, title: e.target.value }) }} />
                        </FormItem>
                    }
                    {/* falta aqui el defaulValue */}
                    {/* {postData?.images === true &&
                        <div className="space-y-8">
                            <div>
                                <span className="text-lg font-semibold">Imágenes de la publicación</span>
                                <div className="mt-5 ">
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-neutral-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                ></path>
                                            </svg>
                                            <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                >
                                                    <span>Cargar un archivo</span>
                                                    <input
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        className="sr-only"
                                                        multiple
                                                        accept=".jpg, .jpeg, .png"
                                                    // onChange={(e) => { handleFileChange(e) }}
                                                    />
                                                </label>
                                                <p className="pl-1">o arrastrar y soltar</p>
                                            </div>
                                            <p className="text-xs text-neutral-500 dark:text-neutral-400">
                                                PNG, JPG, GIF hasta 10MB
                                            </p>
                                            <div>
                                                <ul>{fileNames}</ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}

                    {postData?.description === true &&
                        <FormItem
                            label="Descripción"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Textarea placeholder="..." defaultValue={postForm.description} rows={7} onChange={(e) => { setPostForm({ ...postForm, description: e.target.value }) }} />
                        </FormItem>
                    }

                    {postData?.description_short === true &&
                        <FormItem
                            label="Descripción corta"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Textarea placeholder="..." defaultValue={postForm.description_short} rows={3} onChange={(e) => { setPostForm({ ...postForm, description_short: e.target.value }) }} />
                        </FormItem>
                    }

                    {postData?.price === true &&
                        <FormItem
                            label="Precio"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Input className="custom-input" placeholder="..." defaultValue={postForm.price} type="number" onChange={(e) => { setPostForm({ ...postForm, price: e.target.value }) }} />
                        </FormItem>
                    }

                    {postData?.characteristics === true &&
                        <FormItem
                            label="Características"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Textarea placeholder="..." defaultValue={postForm.characteristics} rows={7} onChange={(e) => { setPostForm({ ...postForm, characteristics: e.target.value }) }} />
                        </FormItem>
                    }

                    {(postData?.city === true) && (postData?.country === true) &&
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                            <FormItem label="País">
                                <Select defaultValue={postForm.country} onChange={(e) => { setPostForm({ ...postForm, country: e.target.value }) }}>
                                    <option value="">-- Seleccionar --</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="France">Francia</option>
                                    <option value="Singapore">Singapur</option>
                                    <option value="Jappan">Japón</option>
                                    <option value="Korea">Korea</option>
                                </Select>
                            </FormItem>
                            <FormItem label="Ciudad">
                                <Input placeholder="..." defaultValue={postForm.city} onChange={(e) => { setPostForm({ ...postForm, city: e.target.value }) }} />
                            </FormItem>
                        </div>
                    }

                    {postData?.contact_name === true &&
                        <FormItem
                            label="Nombre de Contacto"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Input placeholder="..." defaultValue={postForm.contact_name} onChange={(e) => { setPostForm({ ...postForm, contact_name: e.target.value }) }} />
                        </FormItem>
                    }

                    {postData?.contact_phone === true &&
                        <FormItem
                            label="Teléfono de Contacto"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <PhoneInput
                                inputClass="focus:!border-primary-300 focus:!ring focus:!ring-primary-200 focus:!ring-opacity-50 !bg-white"
                                inputStyle={{
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.75rem',
                                    paddingRight: '1rem',
                                    paddingLeft: '4rem',
                                    width: '100%',
                                    borderRadius: '1rem',
                                    fontWeight: '400',
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem',
                                    marginTop: '0.25rem'
                                }}
                                onlyCountries={[
                                    've',
                                    'us',
                                    'pa',
                                    'co',
                                ]}
                                masks={{
                                    ve: '... ... .. ..',
                                }}
                                country={'ve'}
                                placeholder="+00 000 000 00 00"
                                value={postForm.contact_phone}
                                onChange={(phone) => {
                                    setPostForm({ ...postForm, contact_phone: phone })
                                }}
                            />
                        </FormItem>
                    }

                    {postData?.contact_email === true &&
                        <FormItem
                            label="Email de Contacto"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Input placeholder="..." defaultValue={postForm.contact_email} type="email" onChange={(e) => { setPostForm({ ...postForm, contact_email: e.target.value }) }} />
                        </FormItem>
                    }

                    {postData?.contact_address === true &&
                        <FormItem
                            label="Dirección de Contacto"
                        // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
                        >
                            <Input placeholder="..." defaultValue={postForm.contact_address} onChange={(e) => { setPostForm({ ...postForm, contact_address: e.target.value }) }} />
                        </FormItem>
                    }
                </div>
            </>
        </CommonLayout>
    )
}

export default EditPostPage