import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Input from "shared/Input/Input";
import googleSvg from "images/Google.svg";
import { useSelector } from "react-redux";
import Select from "shared/Select/Select";
import PhoneInput from 'react-phone-input-2';
import { DecodeToken } from "utils/JwtConfig";
import 'react-phone-input-2/lib/bootstrap.css';
// import twitterSvg from "images/Twitter.svg";
// import facebookSvg from "images/Facebook.svg";
import { authentication, db, storage } from "utils/firebase";
import { Link, useNavigate } from "react-router-dom";
import { selectIsPageIn } from "redux/slice/tokenSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continua con Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continua con Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continua con Google",
    href: "#",
    icon: googleSvg,
  },
];

interface DataPage {
  name: any;
  subtitle: any;
}

interface DataUser {
  address?: string,
  email?: string,
  id?: string,
  key: string,
  name?: string,
  phone: string,
  status: string,
  subscribed_clients: Array<any>,
  subscribed_portals: Array<any>,
  wallet: number;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [dataPage, setDataPage] = useState<DataPage>();
  const [dataUSerExist, setDataUSerExist] = useState<DataUser | null>();
  const [user, setUser] = useState({
    email: "",
    pass: "",
    confirmPass: "",
    name: "",
    phone: "",
    id: "",
    type: "",
    images: null as FileList | null,
  });

  const navigate = useNavigate();
  const Token = useSelector(selectIsPageIn);

  const uploadFile = (storageRef: any, file: any) => {
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const percentDocument = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("🚀 ~ file: PageAddListing1.tsx:130 ~ uploadTask.on ~ percentDocument:", percentDocument)
      }, (error) => {
        console.log("🚀 ~ file: registrationForm.js:79 ~ uploadTask.on ~ error", error);
        reject(error);
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve(url);
        }).catch((error) => {
          console.log("🚀 ~ file: registrationForm.js:79 ~ getDownloadURL ~ error", error);
          reject(error);
        });
      });
    });
  };

  const getNonEmptyValues = (obj: typeof user) => {
    const nonEmptyValues: any = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value !== '' && value !== null) {
        nonEmptyValues[key] = value;
      }
    });
    return nonEmptyValues;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const DataToken = await DecodeToken(Token);
    const Data = getNonEmptyValues(user);

    const account = {
      email: user.email,
      user_id: {
        number: user.id,
        type: user.type
      },
      address: '',
      name: user.name,
      phone: user.phone,
      documents: {},
      status: 'Activo',
      subscribed_clients: [DataToken?.client_id],
      subscribed_portals: [DataToken?.key],
      wallet: 0
    }

    db.collection('users').where('email', '==', account.email).get().then((DataUser) => {
      if (!DataUser.empty) {
        DataUser.forEach((cadaUsuario) => {
          const tempUser: DataUser = {
            address: cadaUsuario.data().address,
            email: cadaUsuario.data().email,
            id: cadaUsuario.data().id,
            key: cadaUsuario.id,
            name: cadaUsuario.data().name,
            phone: cadaUsuario.data().phone,
            status: cadaUsuario.data().status,
            subscribed_clients: cadaUsuario.data().subscribed_clients,
            subscribed_portals: cadaUsuario.data().subscribed_portals,
            wallet: cadaUsuario.data().wallet
          };
          setDataUSerExist(tempUser);
        });
        setLoading(false);
        setUserExist(true);
      } else {
        authentication.createUserWithEmailAndPassword(user.email, user.pass).then(async res => {
          const uidUser = res.user?.uid;
          const storageRef = ref(storage, `/users/${uidUser}`);

          const uploadPromises = Data.images.map((fileRef: { name: string | undefined; file: any; }) => {
            const fileStorageRef = ref(storageRef, fileRef.name);
            return uploadFile(fileStorageRef, fileRef);
          });


          const urls = await Promise.all(uploadPromises);

          account.documents = {
            id: urls[0]
          };

          db.collection('users').doc(uidUser).set(account).then(() => {
            db.collection(`portals/${DataToken?.key}/data`).doc('users').get().then((UsersPortal) => {
              const tempUsersPortal = UsersPortal.data() ?? {};
              tempUsersPortal.users_quantity = tempUsersPortal.users_quantity + 1;
              db.collection(`portals/${DataToken?.key}/data`).doc('users').update({ users_quantity: tempUsersPortal.users_quantity }).then(() => {
                toast.success('¡Usuario registrado correctamente!', {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setLoading(false);
                navigate('/login');
              }).catch((error) => {
                console.log("🚀 ~ file: PageSignUp.tsx:120 ~ db.collection ~ error:", error)
              })
            }).catch((error) => {
              console.log("🚀 ~ file: PageSignUp.tsx:116 ~ db.collection ~ error:", error)
            })
          }).catch((error) => {
            console.log("🚀 ~ file: Login.js:39 ~ authentication.signInWithEmailAndPassword ~ error:", error)
          })
        }).catch((error) => {
          console.log("🚀 ~ file: PageSignUp.tsx:147 ~ authentication.createUserWithEmailAndPassword ~ error:", error)
          if (error.code === 'auth/email-already-in-use') {
            setLoading(false);
            toast.error('El correo electrónico ya está en uso.', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (error.code === 'auth/invalid-email') {
            setLoading(false);
            toast.error('La dirección de correo electrónico está mal formateada.', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
      }
    }).catch((error) => {
      setLoading(false);
      console.log("🚀 ~ file: PageSignUp.tsx:62 ~ db.collection ~ error:", error);
    })
  };

  const isFormValid = () => {
    const { email, pass, confirmPass, name, phone, id, type, images } = user;

    const fieldsValid = [email, pass, confirmPass, name, phone, id, type].every(value => value !== "");

    const imagesValid = images !== null && images.length > 0;

    // if (pass !== confirmPass) {
    //   toast.error('Las contraseñas no coinciden', {
    //     position: "bottom-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }

    return fieldsValid && imagesValid && pass === confirmPass;
  };

  const saveUpdate = async (Info: any) => {
    const DataToken = await DecodeToken(Token);

    db.collection('users').doc(Info.key).update({
      subscribed_clients: Info.subscribed_clients,
      subscribed_portals: Info.subscribed_portals
    }).then(() => {
      db.collection(`portals/${DataToken?.key}/data`).doc('users').get().then((UsersPortal) => {
        const tempUsersPortal = UsersPortal.data() ?? {};
        tempUsersPortal.users_quantity = tempUsersPortal.users_quantity + 1;
        db.collection(`portals/${DataToken?.key}/data`).doc('users').update({ users_quantity: tempUsersPortal.users_quantity }).then(() => {
          toast.success('¡Usuario registrado correctamente!', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading2(false);
          navigate('/login');
        }).catch((error) => {
          console.log("🚀 ~ file: PageSignUp.tsx:120 ~ db.collection ~ error:", error)
        })
      }).catch((error) => {
        console.log("🚀 ~ file: PageSignUp.tsx:116 ~ db.collection ~ error:", error)
      })
    }).catch((error) => {
      setLoading2(false);
      console.log("🚀 ~ file: PageSignUp.tsx:148 ~ db.collection ~ error:", error)
    })
  }

  const updateUserRegister = async () => {
    setLoading2(true);
    const DataToken = await DecodeToken(Token);
    const clientExist = dataUSerExist?.subscribed_clients.find(x => x === DataToken?.client_id);
    const portalExist = dataUSerExist?.subscribed_portals.find(x => x === DataToken?.key);

    if (clientExist === undefined) {
      dataUSerExist?.subscribed_clients.push(DataToken?.client_id);
      dataUSerExist?.subscribed_portals.push(DataToken?.key);
      saveUpdate(dataUSerExist);
    } else if (clientExist !== undefined && portalExist === undefined) {
      dataUSerExist?.subscribed_portals.push(DataToken?.key);
      saveUpdate(dataUSerExist);
    } else if (clientExist !== undefined && portalExist !== undefined) {
      toast.error('Usted ya se encuentra registrado en este portal por favor inicie sesión', {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate('/login')
    }
  };

  const getPageData = async () => {
    const DataPage: any = await DecodeToken(Token);
    const TempPage: DataPage = {
      name: DataPage?.metadata.title,
      subtitle: DataPage?.metadata.subtitle
    }
    setDataPage(TempPage);
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;

    if (files) {
      const firstFiles = Array.from(files)
      setUser({ ...user, images: firstFiles as unknown as FileList });
    }
  };

  const fileList = user.images ? Array.from(user.images) : [];
  const fileNames = fileList.map((file, index) => (
    <li key={index}>{file.name}</li>
  ));

  useEffect(() => {
    getPageData();
  }, [])

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{`${dataPage?.name}`} || Registro</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Registro
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              O
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          {userExist === false &&
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Nombre Completo
                </span>
                <Input
                  type="text"
                  className="mt-1"
                  onChange={e => setUser({ ...user, name: e.target.value })}
                />
              </label>
              <label className="block">
                <div className="grid grid-cols-4 gap-1">
                  <div className="col-span-1">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Cédula
                    </span>
                    <Select onChange={(e) => { setUser({ ...user, type: e.target.value }) }}>
                      <option value="">Seleccionar</option>
                      <option value="V">V</option>
                      <option value="J">J</option>
                      <option value="E">E</option>
                      <option value="G">G</option>
                    </Select>
                  </div>
                  <div className="col-span-3 mt-5">
                    <Input
                      type="number"
                      className="mt-1 custom-input"
                      onChange={e => setUser({ ...user, id: e.target.value })}
                    />
                  </div>
                </div>
              </label>

              <div className="space-y-10">
                <div>
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Documento de Identidad
                  </span>
                  <div className="mt-5 ">
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                          >
                            <span>Cargar un archivo</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                              accept=".jpg, .jpeg, .png"
                              onChange={(e) => { handleFileChange(e) }}
                            />
                          </label>
                          <p className="pl-1">o arrastrar y soltar</p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          PNG, JPG, GIF hasta 10MB
                        </p>
                        <div>
                          <ul>{fileNames}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Teléfono
                </span>
                <PhoneInput
                  inputClass="focus:!border-primary-300 focus:!ring focus:!ring-primary-200 focus:!ring-opacity-50 !bg-white"
                  inputStyle={{
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    paddingRight: '1rem',
                    paddingLeft: '4rem',
                    width: '100%',
                    borderRadius: '1rem',
                    fontWeight: '400',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    marginTop: '0.25rem'
                  }}
                  onlyCountries={[
                    've',
                    'us',
                    'pa',
                    'co',
                  ]}
                  masks={{
                    ve: '... ... .. ..',
                  }}
                  country={'ve'}
                  placeholder="+00 000 000 00 00"
                  onChange={(phone) => {
                    setUser({ ...user, phone: phone })
                  }}
                />
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Dirección de correo electrónico
                </span>
                <Input
                  type="email"
                  placeholder=""
                  className="mt-1"
                  onChange={e => setUser({ ...user, email: e.target.value })}
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Contraseña
                </span>
                <Input
                  type="password"
                  className="mt-1"
                  onChange={e => setUser({ ...user, pass: e.target.value })}
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Confirmar Contraseña
                </span>
                <Input
                  type="password"
                  className="mt-1"
                  onChange={e => setUser({ ...user, confirmPass: e.target.value })}
                />
              </label>

              {user.pass !== user.confirmPass && (
                <p className="text-center text-red-700">Las contraseñas no coinciden</p>
              )}
              <ButtonPrimary disabled={!isFormValid()} loading={loading} type="submit" onClick={($event) => { onSubmit($event) }}>Registrar</ButtonPrimary>
            </form>
          }

          {userExist === true &&
            <div className="grid grid-rows-2 gap-4">
              <h3 className="font-semibold text-2xl text-center mt-5"> Ya existe un usuario registrado con esta información ¿deseas afiliarte a este portal? </h3>
              <ButtonPrimary className="!h-2/5" loading={loading2} onClick={updateUserRegister}>Afiliar</ButtonPrimary>
            </div>
          }


          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Ya tienes una cuenta? {` `}
            <Link to="/login">Iniciar sesión</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
