import React, { FC, useEffect, useState } from "react";
import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import { toast } from 'react-toastify';
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import PhoneInput from "react-phone-input-2";
import { db, storage } from "utils/firebase";
import 'react-phone-input-2/lib/bootstrap.css';
import { useNavigate } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { selectIsPageIn } from "redux/slice/tokenSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CreateToken, DecodeToken } from "utils/JwtConfig";
import { SET_USER, selectUser } from "redux/slice/userSlice";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import NcImage from "shared/NcImage/NcImage";
import RecargaPuntos from 'images/RecargaPuntos.png'

export interface PageAddListing1Props { }

interface PostData {
  characteristics?: boolean,
  city?: boolean,
  contact_address?: boolean,
  contact_email?: boolean,
  contact_name?: boolean,
  contact_phone?: boolean,
  country?: boolean,
  description?: boolean,
  images?: boolean,
  images_max?: number,
  images_min?: number,
  name?: string,
  price?: boolean,
  title?: boolean
}

interface Wallet {
  wallet: any
}

interface PortalPostCount {
  post_quantity: any
}

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const [postData, setPostData] = useState<PostData | null>();
  const [disbleConditionSelect, setDisbleConditionSelect] = useState(true);
  const [typePublications, setTypePublications] = useState<any[]>();
  const [wallet, setWallet] = useState<Wallet>();
  const [loading, setLoading] = useState(false);
  const [postCost, setPostCost] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [postForm, setPostForm] = useState({
    characteristics: '',
    city: '',
    contact_address: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    country: '',
    description: '',
    images: null as FileList | null,
    name: '',
    price: '',
    title: ''
  })

  const user = useSelector(selectUser);
  const portal = useSelector(selectIsPageIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updatedUserData = (id: any) => {
    db.collection('users').doc(id).get().then(async (doc) => {
      const dataUser = doc.data() ?? {};
      dataUser.key = doc.id;
      const Token = await CreateToken(dataUser);
      dispatch(SET_USER({
        user: Token
      }));
      toast.success('¡Publicación realizada con éxito!', {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate('/');
    }).catch((error) => {
      setLoading(false);
      console.log("🚀 ~ file: CheckOutPage.tsx:45 ~ db.collection ~ error:", error)
    })
  };

  const getUserWallet = async () => {
    if (user !== null) {
      const userData = await DecodeToken(user);
      const TempWallet: Wallet = {
        wallet: userData?.wallet,
      };
      setWallet(TempWallet);
    }
  };

  const getClientWallet = async () => {
    try {
      const DataPortal = await DecodeToken(portal);
      const ClientWallet = await db.collection('clients').doc(`${DataPortal?.client_id}`).get();
      const TempWallet: Wallet = {
        wallet: ClientWallet.data()?.wallet,
      }
      return TempWallet;
    } catch (error) {
      console.log("🚀 ~ error:", error);
      throw error;
    }

  }

  const getPostCountPortal = async () => {
    try {
      const DataPortal = await DecodeToken(portal);
      const PortalPost = await db.collection(`portals/${DataPortal?.key}/data`).doc('posts').get();
      const TempPostQuantity: PortalPostCount = {
        post_quantity: PortalPost.data()?.post_quantity,
      };
      return TempPostQuantity;
    } catch (error) {
      console.log("🚀 ~ error:", error);
      throw error;
    }
  }

  const getOptionsTypePublication = async () => {
    try {
      const DataPortal = await DecodeToken(portal);
      const postTypes = await db.collection(`portals/${DataPortal?.key}/data`).doc('post_type').get();
      const data = postTypes.data();
      if (data) {
        const dataArray = Object.values(data);
        setTypePublications(dataArray[0]);
      }
    } catch (error) {
      console.log("🚀 ~ file: PageAddListing1.tsx:65 ~ db.collection ~ error:", error);
    }
  };

  const handleChangeTypePublication = (event: string) => {
    if (event !== "") {
      const value = event.split('_')
      setPostCost(Number(value[1]))
      db.collection('data/post_types/types').doc(value[0]).get().then((PostConfig) => {
        const TempDataPost: PostData = {
          characteristics: PostConfig.data()?.characteristics,
          city: PostConfig.data()?.city,
          contact_address: PostConfig.data()?.contact_address,
          contact_email: PostConfig.data()?.contact_email,
          contact_name: PostConfig.data()?.contact_name,
          contact_phone: PostConfig.data()?.contact_phone,
          country: PostConfig.data()?.country,
          description: PostConfig.data()?.description,
          images: PostConfig.data()?.images,
          images_max: PostConfig.data()?.images_max,
          images_min: PostConfig.data()?.images_min,
          name: PostConfig.data()?.name,
          price: PostConfig.data()?.price,
          title: PostConfig.data()?.title
        };
        setPostForm({
          characteristics: '',
          city: '',
          contact_address: '',
          contact_email: '',
          contact_name: '',
          contact_phone: '',
          country: '',
          description: '',
          images: null as FileList | null,
          name: '',
          price: '',
          title: ''
        });
        setDisbleConditionSelect(false);
        setPostData(TempDataPost);
      }).catch((error) => {
        console.log("🚀 ~ file: PageAddListing1.tsx:17 ~ db.collection ~ error:", error)
      })
    } else {
      setDisbleConditionSelect(true);
      setPostData({})
    }
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    const maxImages = postData?.images_max !== undefined ? postData?.images_max : 5;

    if (files.length > maxImages) {
      const firstFiles = Array.from(files).slice(0, maxImages);
      setPostForm({ ...postForm, images: firstFiles as unknown as FileList });
      return;
    }

    if (files) {
      const firstFiles = Array.from(files)
      setPostForm({ ...postForm, images: firstFiles as unknown as FileList });
    }
  };

  const getNonEmptyValues = (obj: typeof postForm) => {
    const nonEmptyValues: any = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value !== '' && value !== null) {
        nonEmptyValues[key] = value;
      }
    });
    return nonEmptyValues;
  };

  const uploadFile = (storageRef: any, file: any) => {
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const percentDocument = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("🚀 ~ file: PageAddListing1.tsx:130 ~ uploadTask.on ~ percentDocument:", percentDocument)
      }, (error) => {
        console.log("🚀 ~ file: registrationForm.js:79 ~ uploadTask.on ~ error", error);
        reject(error);
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve(url);
        }).catch((error) => {
          console.log("🚀 ~ file: registrationForm.js:79 ~ getDownloadURL ~ error", error);
          reject(error);
        });
      });
    });
  };

  const savePublication = async () => {
    setLoading(true);
    const totalPostPortal = await getPostCountPortal();
    const walletClient = await getClientWallet();
    const DataUser = await DecodeToken(user);
    const DataPortal = await DecodeToken(portal);
    const Data = getNonEmptyValues(postForm);
    Data.post_type = postData?.name;
    Data.status = 'Completado';
    Data.user_id = DataUser?.key;
    Data.user = DataUser?.name;
    Data.client_name = DataPortal?.client;
    Data.client_id = DataPortal?.client_id;
    Data.portal_name = DataPortal?.portal_name;
    Data.portal_id = DataPortal?.key;
    Data.created_day = new Date();

    const walletData = {
      ammount: postCost * -1,
      data: {
        portal_id: DataPortal?.key,
        portal_name: DataPortal?.portal_name,
        post_id: '',
        post_name: Data.title
      },
      date_created: new Date(),
      date_updated: new Date(),
      order_type: 'post_publication',
      client_name: DataPortal?.client,
      client_id: DataPortal?.client_id,
      product_reference: DataPortal?.portal_name,
      user_name: DataUser?.name,
      user_id: DataUser?.key,
    }

    const walletDataClient = {
      ammount: postCost,
      data: {
        portal_id: DataPortal?.key,
        portal_name: DataPortal?.portal_name,
        post_id: '',
        post_name: Data.title
      },
      date_created: new Date(),
      date_updated: new Date(),
      client_name: DataPortal?.client,
      client_id: DataPortal?.client_id,
      user_name: DataUser?.name,
      user_id: DataUser?.key,
      order_type: 'publication',
      product_reference: DataPortal?.portal_name,
      status: 'Completo'
    }

    if (wallet?.wallet < postCost) {
      setLoading(false);
      toast.error('¡No tienes saldo suficiente en tu cartera por favor recarga saldo!', {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      if (Data.images) {
        try {
          // Crea una instancia de batch
          const batch = db.batch();

          // Crea una referencia a la colección "portals/{portalId}/posts"
          const portalPostsRef = db.collection(`portals/${DataPortal?.key}/posts`);

          // Genera un nuevo ID para el documento en "portals/{portalId}/posts"
          const newPortalPostRef = portalPostsRef.doc();
          const newPortalPostId = newPortalPostRef.id;
          Data.portal_post_id = newPortalPostId;

          const uidPortal = DataPortal?.key;
          const storageRef = ref(storage, `/portals/${uidPortal}/posts/${newPortalPostId}`);

          const uploadPromises = Data.images.map((fileRef: { name: string | undefined; file: any; }) => {
            const fileStorageRef = ref(storageRef, fileRef.name);
            return uploadFile(fileStorageRef, fileRef);
          });

          const urls = await Promise.all(uploadPromises);

          Data.images = urls;


          // Crea los datos que deseas agregar en "portals/{portalId}/posts"
          const portalPostData = { ...Data };

          // Agrega la escritura en "portals/{portalId}/posts" al lote
          batch.set(newPortalPostRef, portalPostData);

          // Crea una referencia a la colección "users/{userId}/posts"
          const userPostsRef = db.collection(`users/${DataUser?.key}/posts`);

          // Genera un nuevo ID para el documento en "users/{userId}/posts"
          const newUserPostRef = userPostsRef.doc(newPortalPostId);

          // Crea los datos que deseas agregar en "users/{userId}/posts"
          const userPostData = { ...Data };

          // Agrega la escritura en "users/{userId}/posts" al lote
          batch.set(newUserPostRef, userPostData);

          // Crea una referencia a la colección "users"
          const usersRef = db.collection('users');

          // Calcula el nuevo valor de "wallet"
          const totalWallet = Number(DataUser?.wallet) - postCost;

          // Crea una referencia al documento del usuario en "users"
          const userRef = usersRef.doc(`${DataUser?.key}`);

          // Agrega la escritura para actualizar "wallet" al lote
          batch.update(userRef, { wallet: totalWallet });

          //actualiza el valor de product_reference con el id de la orden
          walletData.data.post_id = newPortalPostId;

          // Crea una referencia a la colección "users/{userId}/wallet"
          const userWalletRef = usersRef.doc(`${DataUser?.key}`).collection('wallet');

          // Genera un nuevo ID para el documento en "users/{userId}/wallet"
          const newUserWalletRef = userWalletRef.doc();
          const newWalletOrderId = newUserWalletRef.id;

          // Agrega la escritura en "users/{userId}/orders" al lote
          batch.set(newUserWalletRef, walletData);

          // Crea una referencia a la colección "clients"
          const clientsRef = db.collection('clients');

          // Calcula el nuevo valor de "wallet"
          const totalWalletClient = Number(walletClient?.wallet) + postCost;

          // Crea una referencia al documento del usuario en "users"
          const clientRef = clientsRef.doc(`${DataPortal?.client_id}`);

          // Agrega la escritura para actualizar "wallet" al lote
          batch.update(clientRef, { wallet: totalWalletClient });

          const portalsRef = db.collection(`portals/${uidPortal}/data`);

          const newTotalPostPortal = totalPostPortal?.post_quantity + 1;

          const portalRef = portalsRef.doc('posts');

          batch.update(portalRef, { post_quantity: newTotalPostPortal })

          //actualiza el valor de product_reference con el id de la orden
          walletDataClient.data.post_id = newPortalPostId;

          // Crea una referencia a la colección "users/{userId}/wallet"
          const clientWalletRef = clientsRef.doc(`${DataPortal?.client_id}`).collection('wallet');

          // Genera un nuevo ID para el documento en "users/{userId}/wallet"
          const newclientWalletRef = clientWalletRef.doc(newWalletOrderId);

          // Agrega la escritura en "users/{userId}/orders" al lote
          batch.set(newclientWalletRef, walletDataClient);

          // Ejecuta el lote de escrituras
          batch.commit().then(function () {
            updatedUserData(DataUser?.key);
          }).catch(function (error) {
            setLoading(false);
            console.error('Error al realizar las escrituras por lote:', error);
          });
        } catch (error) {
          setLoading(false);
          console.log('Error al subir las imágenes:', error);
        }
      } else {
        // Crea una instancia de batch
        const batch = db.batch();
        const uidPortal = DataPortal?.key;

        // Crea una referencia a la colección "portals/{portalId}/posts"
        const portalPostsRef = db.collection(`portals/${DataPortal?.key}/posts`);

        // Genera un nuevo ID para el documento en "portals/{portalId}/posts"
        const newPortalPostRef = portalPostsRef.doc();
        const newPortalPostId = newPortalPostRef.id;
        Data.portal_post_id = newPortalPostId;

        // Crea los datos que deseas agregar en "portals/{portalId}/posts"
        const portalPostData = { ...Data };

        // Agrega la escritura en "portals/{portalId}/posts" al lote
        batch.set(newPortalPostRef, portalPostData);

        // Crea una referencia a la colección "users/{userId}/posts"
        const userPostsRef = db.collection(`users/${DataUser?.key}/posts`);

        // Genera un nuevo ID para el documento en "users/{userId}/posts"
        const newUserPostRef = userPostsRef.doc();

        // Crea los datos que deseas agregar en "users/{userId}/posts"
        const userPostData = { ...Data };

        // Agrega la escritura en "users/{userId}/posts" al lote
        batch.set(newUserPostRef, userPostData);

        // Crea una referencia a la colección "users"
        const usersRef = db.collection('users');

        // Calcula el nuevo valor de "wallet"
        const totalWallet = Number(DataUser?.wallet) - postCost;

        // Crea una referencia al documento del usuario en "users"
        const userRef = usersRef.doc(`${DataUser?.key}`);

        // Agrega la escritura para actualizar "wallet" al lote
        batch.update(userRef, { wallet: totalWallet });

        //actualiza el valor de product_reference con el id de la orden
        walletData.data.post_id = newPortalPostId;

        // Crea una referencia a la colección "users/{userId}/wallet"
        const userWalletRef = usersRef.doc(`${DataUser?.key}`).collection('wallet');

        // Genera un nuevo ID para el documento en "users/{userId}/wallet"
        const newUserWalletRef = userWalletRef.doc();

        // Agrega la escritura en "users/{userId}/orders" al lote
        batch.set(newUserWalletRef, walletData);

        // Crea una referencia a la colección "clients"
        const clientsRef = db.collection('clients');

        // Calcula el nuevo valor de "wallet"
        const totalWalletClient = Number(walletClient?.wallet) + postCost;

        // Crea una referencia al documento del usuario en "users"
        const clientRef = clientsRef.doc(`${DataPortal?.client_id}`);

        // Agrega la escritura para actualizar "wallet" al lote
        batch.update(clientRef, { wallet: totalWalletClient });

        const portalsRef = db.collection(`portals/${uidPortal}/data`);

        const newTotalPostPortal = totalPostPortal?.post_quantity + 1;

        const portalRef = portalsRef.doc('posts');

        batch.update(portalRef, { post_quantity: newTotalPostPortal })

        //actualiza el valor de product_reference con el id de la orden
        walletDataClient.data.post_id = newPortalPostId;

        // Crea una referencia a la colección "users/{userId}/wallet"
        const clientWalletRef = clientsRef.doc(`${DataPortal?.client_id}`).collection('wallet');

        // Genera un nuevo ID para el documento en "users/{userId}/wallet"
        const newclientWalletRef = clientWalletRef.doc();

        // Agrega la escritura en "users/{userId}/orders" al lote
        batch.set(newclientWalletRef, walletDataClient);

        // Ejecuta el lote de escrituras
        batch.commit().then(function () {
          updatedUserData(DataUser?.key);
        }).catch(function (error) {
          setLoading(false);
          console.error('Error al realizar las escrituras por lote:', error);
        });
      }
    }
  };

  const fileList = postForm.images ? Array.from(postForm.images) : [];
  const fileNames = fileList.map((file, index) => (
    <li key={index}>{file.name}</li>
  ));

  useEffect(() => {
    getUserWallet();
    getOptionsTypePublication();
  }, [])

  useEffect(() => {
    const isTitleValid = postData?.title ? !!postForm.title : true;
    const isCityValid = postData?.city ? !!postForm.city : true;
    const isCharacteristicsValid = postData?.characteristics ? !!postForm.characteristics : true;
    const isContactAddressValid = postData?.contact_address ? !!postForm.contact_address : true;
    const isContactEmailValid = postData?.contact_email ? !!postForm.contact_email : true;
    const isContactNameValid = postData?.contact_name ? !!postForm.contact_name : true;
    const isContactPhoneValid = postData?.contact_phone ? !!postForm.contact_phone : true;
    const isCountryValid = postData?.country ? !!postForm.country : true;
    const isDescriptionValid = postData?.description ? !!postForm.description : true;
    const isImagesValid = postData?.images ? !!postForm.images : true;
    const isPriceValid = postData?.price ? !!postForm.price : true;

    setIsFormValid(isTitleValid && isCityValid && isCharacteristicsValid && isContactAddressValid && isContactEmailValid && isContactNameValid && isContactPhoneValid && isCountryValid && isDescriptionValid && isImagesValid && isPriceValid);
  }, [postForm, postData]);

  return (
    <CommonLayout
      index="01"
      nextBtnText="Publicar"
      nextFunction={savePublication}
      disbleConditionSelect={disbleConditionSelect}
      BtnLoading={loading}
      disableConditionsInput={!isFormValid}
    >
      <>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2">
            <h2 className="text-2xl font-semibold">Datos de la Publicación</h2>
          </div>
          {postCost !== 0 &&
            <div className="col-span-2">
              <span className="text-lg font-semibold">Costo de la publicación: {postCost} puntos</span>
            </div>
          }
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {wallet?.wallet <= 0 &&
          <>
            <div className="flex justify-center">
              <NcImage className="w-2/6" src={RecargaPuntos} />
            </div>
            <div className="grid grid-rows-1">
              <h2 className="font-semibold text-2xl text-center mt-5">No tienes saldo suficiente en tu cuenta</h2>
              <h2 className="font-semibold text-2xl text-center">¿deseas recargar tu saldo?</h2>
              <ButtonPrimary className="!h-2/5 mt-6" href={'/subscription'}>Recargar</ButtonPrimary>
            </div>
          </>
        }

        {wallet?.wallet > 0 &&
          <div className="space-y-8">
            {/* ITEM */}
            <FormItem
              label="Elija un tipo de publicación"
            // desc="Hotel: Professional hospitality businesses that usually have a unique style or theme defining their brand and decor"
            >
              <Select onChange={(e) => { handleChangeTypePublication(e.target.value) }}>
                <option value="">-- Seleccionar --</option>
                {typePublications?.map((cadaOpcion, index) => {
                  return (
                    <option key={index} value={cadaOpcion.value + '_' + cadaOpcion.cost}>{cadaOpcion.name}</option>
                  )
                })
                }
              </Select>
            </FormItem>
            {postData?.title === true &&
              <FormItem
                label="Titulo"
              // desc="A catchy name usually includes: House name + Room name + Featured property + Tourist destination"
              >
                <Input placeholder="titulo de la publicación" onChange={(e) => { setPostForm({ ...postForm, title: e.target.value }) }} />
              </FormItem>
            }

            {postData?.images === true &&
              <div className="space-y-8">
                <div>
                  <span className="text-lg font-semibold">Imágenes de la publicación</span>
                  <div className="mt-5 ">
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                          >
                            <span>Cargar un archivo</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                              multiple
                              accept=".jpg, .jpeg, .png"
                              onChange={(e) => { handleFileChange(e) }}
                            />
                          </label>
                          <p className="pl-1">o arrastrar y soltar</p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          PNG, JPG, GIF hasta 10MB
                        </p>
                        <div>
                          <ul>{fileNames}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {postData?.description === true &&
              <FormItem
                label="Descripción"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <Textarea placeholder="descripción de la publicación" rows={7} onChange={(e) => { setPostForm({ ...postForm, description: e.target.value }) }} />
              </FormItem>
            }

            {postData?.price === true &&
              <FormItem
                label="Precio"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <Input className="custom-input" placeholder="precio de la publicación" type="number" onChange={(e) => { setPostForm({ ...postForm, price: e.target.value }) }} />
              </FormItem>
            }

            {postData?.characteristics === true &&
              <FormItem
                label="Características"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <Textarea placeholder="características de la publicación" rows={7} onChange={(e) => { setPostForm({ ...postForm, characteristics: e.target.value }) }} />
              </FormItem>
            }

            {(postData?.city === true) && (postData?.country === true) &&
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="País">
                  <Select onChange={(e) => { setPostForm({ ...postForm, country: e.target.value }) }}>
                    <option value="">-- Seleccionar --</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="France">Francia</option>
                    <option value="Singapore">Singapur</option>
                    <option value="Jappan">Japón</option>
                    <option value="Korea">Korea</option>
                  </Select>
                </FormItem>
                <FormItem label="Ciudad">
                  <Input placeholder="ciudad donde ofrece la publicación" onChange={(e) => { setPostForm({ ...postForm, city: e.target.value }) }} />
                </FormItem>
              </div>
            }

            {postData?.contact_name === true &&
              <FormItem
                label="Nombre de Contacto"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <Input placeholder="nombre de contacto para la publicación" onChange={(e) => { setPostForm({ ...postForm, contact_name: e.target.value }) }} />
              </FormItem>
            }

            {postData?.contact_phone === true &&
              <FormItem
                label="Teléfono de Contacto"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <PhoneInput
                  inputClass="focus:!border-primary-300 focus:!ring focus:!ring-primary-200 focus:!ring-opacity-50 !bg-white"
                  inputStyle={{
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    paddingRight: '1rem',
                    paddingLeft: '4rem',
                    width: '100%',
                    borderRadius: '1rem',
                    fontWeight: '400',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    marginTop: '0.25rem'
                  }}
                  onlyCountries={[
                    've',
                    'us',
                    'pa',
                    'co',
                  ]}
                  masks={{
                    ve: '... ... .. ..',
                  }}
                  country={'ve'}
                  placeholder="+00 000 000 00 00"
                  onChange={(phone) => {
                    setPostForm({ ...postForm, contact_phone: phone })
                  }}
                />
              </FormItem>
            }

            {postData?.contact_email === true &&
              <FormItem
                label="Email de Contacto"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <Input placeholder="correo electrónico de contacto para la publicación" type="email" onChange={(e) => { setPostForm({ ...postForm, contact_email: e.target.value }) }} />
              </FormItem>
            }

            {postData?.contact_address === true &&
              <FormItem
                label="Dirección de Contacto"
              // desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
              >
                <Input placeholder="dirección de contacto para la publicación" onChange={(e) => { setPostForm({ ...postForm, contact_address: e.target.value }) }} />
              </FormItem>
            }
          </div>
        }
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
