import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import tokenReducer from './slice/tokenSlice';
import userReducer from './slice/userSlice';
import productReducer from './slice/productSlice';
import invoiceReducer from './slice/invoiceSlice';
import postReducer from './slice/postSlice';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const rootReducer = combineReducers({
    token: tokenReducer,
    user: userReducer,
    product: productReducer,
    invoice: invoiceReducer,
    post: postReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

const Persistor = persistStore(store);

export { Persistor };
export default store;