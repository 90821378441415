import React, { FC, useEffect, useState } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import StartRating from 'components/StartRating/StartRating';
import { useSelector } from 'react-redux';
import { selectPost } from 'redux/slice/postSlice';
import { db } from 'utils/firebase';
import { DecodeToken } from 'utils/JwtConfig';
import { selectIsPageIn } from 'redux/slice/tokenSlice';
import { selectUser } from 'redux/slice/userSlice';
import avatar1 from "images/avatars/Image-1.png";

export interface ContactUserPageProps {
  className?: string;
}

interface PostData {
  user: any;
  contact_phone: any;
  title: any;
  images: any;
  city: any;
  contact_address: any;
  user_id: any;
  description: any;
  status: any;
  contact_name: any;
  price: any;
  post_type: any;
  country: any;
  contact_email: any;
}

const ContactUserPage: FC<ContactUserPageProps> = ({ className = "" }) => {
  const [postData, setPostData] = useState<PostData>();

  const PostID = useSelector(selectPost);
  const Portal = useSelector(selectIsPageIn);
  const User = useSelector(selectUser);

  const getPostData = async () => {
    const PortalData = await DecodeToken(Portal)
    const currentUrl = window.location.href;
    const splitUrl = currentUrl.split('/');
    
    db.collection(`portals/${PortalData?.key}/posts`).doc(PostID === null ? splitUrl[4] : PostID).get().then(async (post) => {
      if (post.exists) {
        const TempPost: PostData = {
          user: post.data()?.user,
          contact_phone: post.data()?.contact_phone,
          title: post.data()?.title,
          images: post.data()?.images,
          city: post.data()?.city,
          contact_address: post.data()?.contact_address,
          user_id: post.data()?.user_id,
          description: post.data()?.description,
          status: post.data()?.status,
          contact_name: post.data()?.contact_name,
          price: post.data()?.price,
          post_type: post.data()?.post_type,
          country: post.data()?.country,
          contact_email: post.data()?.contact_email
        };
        setPostData(TempPost);
      } else {
        const DataUser = await DecodeToken(User);
        db.collection(`users/${DataUser?.key}/posts`).doc(PostID).get().then((post) => {
          const TempPost: PostData = {
            user: post.data()?.user,
            contact_phone: post.data()?.contact_phone,
            title: post.data()?.title,
            images: post.data()?.images,
            city: post.data()?.city,
            contact_address: post.data()?.contact_address,
            user_id: post.data()?.user_id,
            description: post.data()?.description,
            status: post.data()?.status,
            contact_name: post.data()?.contact_name,
            price: post.data()?.price,
            post_type: post.data()?.post_type,
            country: post.data()?.country,
            contact_email: post.data()?.contact_email
          };
          setPostData(TempPost);
        })
      }
    }).catch((error) => {
      console.log("🚀 ~ file: ListingStayDetailPage.tsx:565 ~ db.collection ~ error:", error)
    })
  };

  useEffect(() => {
    getPostData();
  }, [])

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Datos del contacto
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src='https://as2.ftcdn.net/v2/jpg/00/97/00/09/1000_F_97000908_wwH2goIihwrMoeV9QF3BW6HtpsVFaNVM.jpg' />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {postData?.user}
                </span>
              </div>
              <div>
                <span className="text-base font-medium mt-1 block">
                  <i className="las la-map-marker-alt"></i>
                  {postData?.country} -
                  {postData?.city}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {postData?.title}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating />
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Detalle del Contacto</h3>
          <div className="flex flex-col space-y-4">
            {postData?.contact_name !== undefined &&
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Nombre de contacto</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {postData?.contact_name}
                </span>
              </div>
            }
            {postData?.contact_email !== undefined &&
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Correo Electrónico de contacto</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {postData?.contact_email}
                </span>
              </div>
            }
            {postData?.contact_phone !== undefined &&
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Numero Telefónico de contacto</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  +{postData?.contact_phone}
                </span>
              </div>
            }
            {postData?.contact_address !== undefined &&
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Dirección de contacto</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {postData?.contact_address}
                </span>
              </div>
            }
          </div>
        </div>
        <div>
          {/* <ButtonPrimary href="/add-listing-1">Publica un anuncio</ButtonPrimary> */}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ContactUser ${className}`} data-nc-id="ContactUserPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  )
}

export default ContactUserPage