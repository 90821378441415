import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { db } from "utils/firebase";
import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";
import Heading from "shared/Heading/Heading";
import { DecodeToken } from "utils/JwtConfig";
import StayCard from "components/StayCard/StayCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { selectIsPageIn } from "redux/slice/tokenSlice";

//
export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  tabs?: string[];
}

interface Post {
  title?: string;
  content?: string;
  key: string;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Publicados Recientemente",
  subHeading = "Ordenados por Fecha de Publicación",
  tabs = ["Todas", "Aviso Legal", "Oferta Laboral", "Inmuebles", "Venta de Productos", "Publicación de Vehículo"],
}) => {
  const [tabsPost, setTabsPost] = useState<any[]>([]);
  const [dataPosts, setDataPosts] = useState<any[]>([]);
  const [dataPostsSales, setDataPostsSales] = useState<any[]>([]);
  const [dataPostsLegal, setDataPostsLegal] = useState<any[]>([]);
  const [dataPostsVehicles, setDataPostsVehicles] = useState<any[]>([]);
  const [dataPostsJobOffers, setDataPostsJobOffers] = useState<any[]>([]);
  const [dataPostsRealestate, setDataPostsRealestate] = useState<any[]>([]);

  const Token = useSelector(selectIsPageIn);

  const GetPost = async () => {
    try {
      const DataToken = await DecodeToken(Token);
      const postTabs = await db.collection(`portals/${DataToken?.key}/data`).doc('post_type').get();
      const PostsData = await db.collection(`portals/${DataToken?.key}/posts`).where('status', '==', 'Completado').limit(8).get();
      const PostDataLegal = await db.collection(`portals/${DataToken?.key}/posts`).where('post_type', '==', 'Aviso Legal').where('status', '==', 'Completado').limit(8).get();
      const PostDataRealestate = await db.collection(`portals/${DataToken?.key}/posts`).where('post_type', '==', 'Inmuebles').where('status', '==', 'Completado').limit(8).get();
      const PostDataJobOffers = await db.collection(`portals/${DataToken?.key}/posts`).where('post_type', '==', 'Oferta Laboral').where('status', '==', 'Completado').limit(8).get();
      const PostDataSales = await db.collection(`portals/${DataToken?.key}/posts`).where('post_type', '==', 'Venta de Productos').where('status', '==', 'Completado').limit(8).get();
      const PostsDataVehicle = await db.collection(`portals/${DataToken?.key}/posts`).where('post_type', '==', 'Publicacion de Vehiculo').where('status', '==', 'Completado').limit(8).get();

      const Post: Post[] = PostsData.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostVehicles: Post[] = PostsDataVehicle.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostJobOffer: Post[] = PostDataJobOffers.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostRealestate: Post[] = PostDataRealestate.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostSales: Post[] = PostDataSales.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostLegal: Post[] = PostDataLegal.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const Tabs: any[] = postTabs.data()?.data.map((cadatipoPost: any) => {
        return cadatipoPost.name;
      });

      const sortedTabs = ['Todas', ...Tabs].sort((a, b) => {
        const indexA = tabs.indexOf(a);
        const indexB = tabs.indexOf(b);
        return indexA - indexB;
      });

      setDataPosts(Post);
      setTabsPost(sortedTabs);
      setDataPostsSales(PostSales);
      setDataPostsLegal(PostLegal);
      setDataPostsVehicles(PostVehicles);
      setDataPostsJobOffers(PostJobOffer);
      setDataPostsRealestate(PostRealestate);
    } catch (error) {
      console.log("🚀 ~ file: PageHome.tsx:135 ~ db.collection ~ error:", error);
    }
  };

  useEffect(() => {
    GetPost();
  }, [])

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <Tab.Group>
        <Tab.List className="flex space-x-1 overflow-x-auto">
          {tabsPost.map((item) => (
            <Tab key={item} as={Fragment}>
              {({ selected }) => (
                <button
                  className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                    ? "bg-secondary-700 text-secondary-50 "
                    : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                >
                  {item}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="">
            <div className={`grid mt-6 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
              {dataPosts?.filter((_, i) => i < 8).map((stay) => (
                <StayCard key={stay.key} data={stay} />
              ))}
            </div>
            <div className="flex mt-11 justify-center items-center">
              <ButtonPrimary loading={false} >Ver Todos</ButtonPrimary>
            </div>
          </Tab.Panel>

          {tabsPost.includes('Aviso Legal') &&
            <Tab.Panel className="">
              <div className={`grid mt-6 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
                {dataPostsLegal?.filter((_, i) => i < 8).map((stay) => (
                  <StayCard key={stay.key} data={stay} />
                ))}
              </div>
              <div className="flex mt-11 justify-center items-center">
                <ButtonPrimary loading={false} >Ver Todos</ButtonPrimary>
              </div>
            </Tab.Panel>
          }

          {tabsPost.includes('Oferta Laboral') &&
            <Tab.Panel className="">
              <div className={`grid mt-6 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
                {dataPostsJobOffers?.filter((_, i) => i < 8).map((stay) => (
                  <StayCard key={stay.key} data={stay} />
                ))}
              </div>
              <div className="flex mt-11 justify-center items-center">
                <ButtonPrimary loading={false} >Ver Todos</ButtonPrimary>
              </div>
            </Tab.Panel>
          }

          {tabsPost.includes('Inmuebles') &&
            <Tab.Panel className="">
              <div className={`grid mt-6 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
                {dataPostsRealestate?.filter((_, i) => i < 8).map((stay) => (
                  <StayCard key={stay.key} data={stay} />
                ))}
              </div>
              <div className="flex mt-11 justify-center items-center">
                <ButtonPrimary loading={false} >Ver Todos</ButtonPrimary>
              </div>
            </Tab.Panel>
          }

          {tabsPost.includes('Venta de Productos') &&
            <Tab.Panel className="">
              <div className={`grid mt-6 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
                {dataPostsSales?.filter((_, i) => i < 8).map((stay) => (
                  <StayCard key={stay.key} data={stay} />
                ))}
              </div>
              <div className="flex mt-11 justify-center items-center">
                <ButtonPrimary loading={false} >Ver Todos</ButtonPrimary>
              </div>
            </Tab.Panel>
          }

          {tabsPost.includes('Publicación de Vehículo') &&
            <Tab.Panel className="">
              <div className={`grid mt-6 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
                {dataPostsVehicles?.filter((_, i) => i < 8).map((stay) => (
                  <StayCard key={stay.key} data={stay} />
                ))}
              </div>
              <div className="flex mt-11 justify-center items-center">
                <ButtonPrimary loading={false} >Ver Todos</ButtonPrimary>
              </div>
            </Tab.Panel>
          }
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default SectionGridFeaturePlaces;
