import React, { FC, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectIsPageIn } from 'redux/slice/tokenSlice';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import { DecodeToken } from 'utils/JwtConfig';
import { authentication } from 'utils/firebase';

export interface ForgotPassProps {
    className?: string;
}

interface DataPage {
    name: any;
    subtitle: any;
}

const ForgotPassPage: FC<ForgotPassProps> = ({ className = "" }) => {
    const [dataPage, setDataPage] = useState<DataPage>();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        user: "",
    });

    const Token = useSelector(selectIsPageIn);

    const getPageData = async () => {
        const DataPage: any = await DecodeToken(Token);
        const TempPage: DataPage = {
            name: DataPage?.metadata.title,
            subtitle: DataPage?.metadata.subtitle
        }
        setDataPage(TempPage);
    };

    const isFormValid = () => {
        return Object.values(user).every(value => value !== '');
    };

    const handleResetPassword = (event: any) => {
        event.preventDefault();
        setLoading(true);
        authentication.sendPasswordResetEmail(user.user).then(() => {
            toast.success('Se ha enviado un correo electrónico para restablecer tu contraseña.', {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
        }).catch((error) => {
            if(error.code === 'auth/invalid-email'){
                toast.error('La dirección de correo electrónico está mal formateada.', {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        })
    };

    useEffect(() => {
        getPageData();
    }, [])

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>{`${dataPage?.name}`} || Recuperar contraseña</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Recuperar Contraseña
                </h2>
                <div className="max-w-md mx-auto space-y-6">
                    {/* FORM */}
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                Dirección de correo electrónico
                            </span>
                            <Input
                                type="email"
                                placeholder=""
                                className="mt-1"
                                onChange={(e) => { setUser({ ...user, user: e.target.value }) }}
                            />
                        </label>
                        <ButtonPrimary type="submit" disabled={!isFormValid()} loading={loading} onClick={($event) => { handleResetPassword($event) }}>Recuperar</ButtonPrimary>
                    </form>
                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        <Link to="/login">Volver al inicio</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassPage