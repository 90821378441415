import React, { FC } from 'react';


export interface LoaderProps {
    className?: string;
}

const Loader: FC<LoaderProps> = () => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white opacity-75 flex flex-col items-center justify-center">
            <svg fill='none' className="w-24 h-24 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                <path clipRule='evenodd'
                    d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                    fill='currentColor' fillRule='evenodd' />
            </svg>
            <h2 className="text-center text-black text-xl font-semibold">Cargando...</h2>
            <p className="w-1/3 text-center text-black">Esto puede tardar unos segundos.</p>
        </div>
    )
}

export default Loader