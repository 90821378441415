import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import { useSelector } from "react-redux";
import MenuBar from "shared/MenuBar/MenuBar";
import SearchDropdown from "./SearchDropdown";
import AvatarDropdown from "./AvatarDropdown";
import { selectUser } from "redux/slice/userSlice";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CircleStackIcon } from "@heroicons/react/24/outline";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { DecodeToken } from "utils/JwtConfig";

export interface MainNav1Props {
  className?: string;
}

interface UserWallet {
  wallet: any
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const [wallet, setWallet] = useState<UserWallet>();
  const user = useSelector(selectUser);

  useEffect(() => {
    const getUserWallet = async () => {
      if (user !== null) {
        const userData = await DecodeToken(user);
        const TempWallet: UserWallet = {
          wallet: userData?.wallet,
        };
        setWallet(TempWallet);
      }
    };

    getUserWallet();
  }, [user])

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden md:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <div className="flex flex-shrink-0 items-center justify-between flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          {/* <HeroSearchForm2MobileFactory /> */}
            <Logo />
            <MenuBar />
          </div>
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/* <SwitchDarkMode /> */}
            <SearchDropdown />
            {user === null &&
              <>
                <div className="px-1" />
                <ButtonPrimary href="/login">Iniciar Sesión</ButtonPrimary>
              </>
            }
            {user !== null &&
              <>
                <div className="px-1" />
                <CircleStackIcon className="w-[18px] h-[18px] text-black" /><span> {wallet?.wallet}</span>
                <div className="px-1" />
                <AvatarDropdown />
                <div className="px-1" />
                <ButtonPrimary href="/add-listing-1">Publicar Anuncio</ButtonPrimary>
              </>
            }
          </div>
          <div className="flex xl:hidden items-center">
            {/* <SwitchDarkMode /> */}
            <div className="px-0.5" />
            {/* {user !== null &&
              <AvatarDropdown />
            } */}
            <MenuBar />
          </div>
        </div>
      </div>
    </div >
  );
};

export default MainNav1;
