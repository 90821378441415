import React, { useEffect, useState } from "react";
import MyRouter from "routers/index";
import Loader from "components/Loader";

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
    </div>
  );
}

export default App;
