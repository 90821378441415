import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectInvoice } from "redux/slice/invoiceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { db } from "utils/firebase";
import CompraExitosa from 'images/CompraExitosa.png';

export interface PayPageProps {
  className?: string;
}

interface OrderDetail {
  client_name: any;
  order_id: any;
  date_created: any
  order_owner: any;
  order_type: any;
  payment_currency: any;
  payment_method: any;
  payment_reference: any;
  payment_status: any;
  payment_subtotal: any;
  payment_total: any;
  product_reference: any;
  status: any;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const [orderDetail, setOrderDetail] = useState<OrderDetail>();
  const invoiceID = useSelector(selectInvoice)

  const getOrderDetail = () => {
    db.collection('orders').doc(invoiceID).get().then((OrderData) => {
      if (OrderData.exists) {
        const TempOrderDetail: OrderDetail = {
          client_name: OrderData.data()?.client_name,
          order_id: OrderData.data()?.order_id,
          date_created: OrderData.data()?.date_created,
          order_owner: OrderData.data()?.order_owner,
          order_type: OrderData.data()?.order_type,
          payment_currency: OrderData.data()?.payment_currency,
          payment_method: OrderData.data()?.payment_method,
          payment_reference: OrderData.data()?.payment_reference,
          payment_status: OrderData.data()?.payment_status,
          payment_subtotal: OrderData.data()?.payment_subtotal,
          payment_total: OrderData.data()?.payment_total,
          product_reference: OrderData.data()?.product_reference,
          status: OrderData.data()?.status
        }
        setOrderDetail(TempOrderDetail);
      }
    }).catch((error) => {
      console.log("🚀 ~ file: PayPage.tsx:20 ~ db.collection ~ error:", error)
    })
  };

  useEffect(() => {
    getOrderDetail();
  }, [])

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          ¡Compra realizad con éxito! 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Detalles del Plan</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={CompraExitosa} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {orderDetail?.product_reference}
                </span>
              </div>
              {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                2 beds · 2 baths
              </span> */}
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating />
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Detalles de la Compra</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Código de compra:</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderDetail?.order_id}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Fecha:</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {new Date(orderDetail?.date_created.toDate()).toLocaleDateString("es-VE")}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total:</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                ${orderDetail?.payment_total}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Método de Pago:</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {orderDetail?.payment_method}
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="/add-listing-1">Publica un anuncio</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
