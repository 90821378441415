import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Token: null,
};

const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        SET_ACTIVE_PAGE: (state, action) => {
            const { token } = action.payload;
            state.Token = token;
        },
    }
});

export const { SET_ACTIVE_PAGE } = tokenSlice.actions;

export const selectIsPageIn = (state: any) => state.token.Token;

export default tokenSlice.reducer;