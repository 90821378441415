import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    InvoiceId: null,
}

const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        SET_INVOICE: (state, action) => {
            state.InvoiceId = action.payload.invoice;
        },
        REMOVE_INVOICE: (state, action) => {
            state.InvoiceId = action.payload.invoice;
        }
    }
});

export const { SET_INVOICE, REMOVE_INVOICE } = invoiceSlice.actions;

export const selectInvoice = (state: any) => state.invoice.InvoiceId;

export default invoiceSlice.reducer;